.node {
  transition: opacity 0.3s ease-in-out;
  fill: #81ccd7;
}
.graph-hover line:not(.connections) {
  stroke: #FB6C71;
  opacity: 0.1;
}

.graph-hover line.primary {
  stroke: #00ACC2;
  opacity: 1;
}

.graph-hover .node text,
.graph-hover .node .triangle {
  opacity: 0.1;
}

.graph-hover .node:not(.primary):not(.secondary) {
  opacity: 0.1;
}

.node.primary,
.node.secondary{
  fill: #81ccd7;
}

.node.inactive{
  fill: #C1C9D2;
}

.node.manager{
  fill: #000000;
}

.graph-hover .node.primary text,
.graph-hover .node.primary .triangle,
.graph-hover .node.secondary .triangle {
  opacity: 1;
}

.graph-hover .node.secondary text,
.graph-hover .node.primary .triangle,
.graph-hover .node.secondary .triangle {
  opacity: 1;
}

.connected-wrapper circle{
  fill: #81ccd7;
}