/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@layer components {
  * {
    font-family: "Inter";
  }

  html {
    overflow: auto !important;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #8595a7;
  }

  input {
    box-shadow: none !important;
  }

  h1 {
    font-size: 64px;
    font-weight: 800;
    line-height: 90px;
    color: #232e3b;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    color: #232e3b;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #232e3b;
  }

  a {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .pl-input {
    @apply focus:ring-pl-primary-green-default focus:border-pl-primary-green-default flex-grow block w-full min-w-0 rounded-md sm:text-sm border-pl-grayscale-lightgray;
  }

  .pl-input-radio {
    @apply focus:ring-pl-primary-green-default h-4 w-4 text-pl-primary-green-default border-pl-grayscale-midgray;
  }

  .pl-input-label {
    @apply block text-sm font-medium text-pl-grayscale-black;
  }

  .pl-input-description {
    @apply block text-xs font-medium text-pl-grayscale-midgray;
  }

  .pl-error-message {
    @apply block text-sm font-medium text-pl-primary-red-default font-normal;
  }

  .div-input-field {
    @apply mt-1 rounded-md shadow-sm flex;
  }

  .div-full-line-form {
    @apply flex flex-col sm:flex-row w-full items-center mt-5;
  }

  .div-col-1of4-form {
    @apply w-full sm:w-1/4;
  }

  .div-col-2of4-form {
    @apply w-full sm:w-2/4;
  }

  .pl-time-picker {
    @apply w-full sm:w-1/4 md:w-2/12 lg:w-1/12;
  }

  .pl-time-picker-text {
    @apply border-0 bg-none text-right p-1 text-sm;
  }

  .pl-button-icon {
    @apply hover:bg-pl-grayscale-lightgray border border-pl-grayscale-lightgray rounded-md px-3 inline-flex items-center text-pl-grayscale-gray sm:text-sm;
  }

  .pl-blur {
    @apply blur-lg;
  }

  .pl-modal-button {
    @apply border border-pl-grayscale-lightgray rounded-md shadow-sm py-2 px-4 mr-2 ml-4 w-20 inline-flex justify-center text-sm font-medium hover:bg-pl-grayscale-lightgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pl-primary-green-default;
  }
}
